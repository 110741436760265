import Button from '@/components/general/Button';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { useMutation } from '@tanstack/react-query';
import { resendOtpQueryFn, verifyOtpQueryFn } from '@/queries/auth';
import useAuth from '@/contexts/AuthContext';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import toast from 'react-hot-toast';
import { Constants } from '@/helpers/Constants';
import i18next from 'i18n';

const OtpForm = ({ fourDigits, buttonText = i18next.t('general.sign-in'), external = false, onSubmit, loading = null, onLoginSuccess }: { fourDigits: string; buttonText?: string; external?: boolean; onSubmit?: (otp: string) => void, loading?: boolean | null; onLoginSuccess?: () => void | null; }) => {
    const [isResend, setIsResend] = useState<boolean>(true);
    const [otp, setOtp] = useState<string>('');
    const { setUser, updateRefreshToken } = useAuth();

    const resendOtpMutation = useMutation({
        mutationFn: resendOtpQueryFn,
        onError: async (error) => {
        },
    });

    const verifyOtpMutation = useMutation({
        mutationFn: verifyOtpQueryFn,
        onSuccess: async (response) => {
            if (response && response.user) {
                setUser(response.user);
                if (onLoginSuccess) {
                    onLoginSuccess();
                }
            }
            if (response && response.refresh_token) {
                updateRefreshToken(response.refresh_token);
            }
        },
        onError: async (error) => {
            if (ErrorHandler.Auth.badOtp(await ErrorHandler.transformError(error as Request))) {
                toast.error(i18next.t('error-notification.invalid-otp-code'));
            }
        },
    });

    return (
        <div className="flex flex-col items-center">
            <span className="font-secondary text-sm text-center mb-4">
                {i18next.t('success-notification.email-verification-sent', { fourDigits })}
            </span>
            <OtpInput
                value={otp}
                onChange={setOtp}
                shouldAutoFocus
                placeholder="0"
                numInputs={6}
                inputType="tel"
                renderSeparator={(index: number) => {
                    return index === 2 ? <span className="text-[#D0D5DD] inline-block w-[46px] text-[30px]">-</span> : '';
                }}
                renderInput={(props) => (
                    <input
                        {...props}
                        placeholder="0"
                        className="!w-[46px] !h-[62px] placeholder:text-[#D0D5DD] text-[30px] mx-1 inline-block border-[1px] border-[#D0D5DD] shadow-input rounded-[8px]"
                    />
                )}
            />
            <span className="text-[13px] text-center font-secondary leading-[13px] mt-6 ">Didn&apos;t get code?</span>
            <button
                className="text-[13px] text-center font-secondary leading-[13px] mt-1"
                onClick={() => {
                    if (isResend) {
                        return;
                    }
                    setIsResend(true);
                    resendOtpMutation.mutate();
                }}
            >
                <span className="text-blue1 ml-1 underline text-center font-secondary leading-[13px] block mt-1">
                    {!isResend ? 'Resend' :
                        (
                            <div className="mt-2">
                                <Countdown
                                    date={Date.now() + Constants.Auth.TIME_FOR_OTP_RESEND}
                                    onComplete={() => setIsResend(false)}
                                    renderer={({ seconds }) => {
                                        return i18next.t('general.can-request-new-otp', { seconds });
                                    }}
                                />
                            </div>
                        )}
                </span>
            </button>

            <Button
                size="md"
                fullWidth
                type="submit"
                loading={!!(verifyOtpMutation.isLoading || loading)}
                disabled={!!(verifyOtpMutation.isLoading || !otp || otp === '' || otp.length < 6 || loading)}
                className="mt-8"
                onClick={async () => {
                    if (external && onSubmit) {
                        onSubmit(otp);
                    } else {
                        verifyOtpMutation.mutate(otp);
                    }
                }}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default OtpForm;
