'use client';

/* eslint-disable react-hooks/rules-of-hooks */
import { Auction, AuctionBid, Item } from '@/api';
import { useFavorite } from '@/hooks/api/useFavorite';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import CalendarIcon from 'public/assets/icons/calendar.svg';
import SandWatchIcon from 'public/assets/icons/sand-watch.svg';
import ShoppingBagIcon from 'public/assets/icons/shopping-bag.svg';
import useAuction from '@/hooks/useAuction';
import { useMemo } from 'react';
import i18next from 'i18n';
import { useMobile } from '@/hooks/useMobile';
import { getPoster } from '@/app/utils/item-utils';
import useAuth from '@/contexts/AuthContext';
import BidTimer from '../general/BidTimer';
import Button from '../general/Button';
import Price from '../general/Price';
import { ResponsiveImage } from '../general/ResponsiveImage';
import AuthCode from './AuthCode';
import ProductBadge from './product/ProductBadge';
import ProductBreadCrumbs from './product/ProductBreadCrumbs';
import DateFormat from '../general/DateFormat';
import SmallLogo from '../general/LogoSmall';

const ProductRow = ({ item, userBid, onActionClicked }: { item: Item | undefined; userBid?: AuctionBid, onActionClicked?: () => void }) => {
    if (!item) {
        return null;
    }
    const router = useRouter();
    const { user } = useAuth();

    const { private: isPrivate, id, tr_id: trId, images, trending } = item;

    let { name } = item;

    if (isPrivate && !user) {
        name = i18next.t('item-text.private-title');
    }

    const { add, remove, isFavorite } = useFavorite();
    const { auction, hasAuction, auctionType, lastBid, isWinning, endTime } = useAuction(item, userBid);

    const lastUserbid = userBid || lastBid;

    const isMobile = useMobile();

    const firstImage = useMemo(() => getPoster(images), [images]);

    const renderPrivateImage = (isPrivate && !user);

    return (
        <div
            onClick={() => {
                router.push(`/item/${item.slug}`);
                onActionClicked?.();
            }}
            className="lg-tablet:min-w-[792px] cursor-pointer transitionable hover:scale-[1.01] rounded-lg bg-white shadow-default my-[12px] overflow-hidden border-[1px] flex lg-tablet:flex-row flex-col relative">

            <div className="absolute right-[30px] top-[12px]">
                <svg id="heart" height="0" width="0">
                    <defs>
                        <clipPath id="svgPath">
                            <path d="M9.99413 4.66852C8.328 2.72067 5.54963 2.19671 3.46208 3.98035C1.37454 5.76399 1.08064 8.74615 2.72 10.8557C4.08302 12.6096 8.20798 16.3088 9.55992 17.506C9.71117 17.64 9.7868 17.707 9.87502 17.7333C9.95201 17.7562 10.0363 17.7562 10.1132 17.7333C10.2015 17.707 10.2771 17.64 10.4283 17.506C11.7803 16.3088 15.9052 12.6096 17.2683 10.8557C18.9076 8.74615 18.6496 5.74523 16.5262 3.98035C14.4028 2.21547 11.6603 2.72067 9.99413 4.66852Z" />
                        </clipPath>
                    </defs>
                </svg>

                <div className="heart-container">
                    <svg width="20" height="20" viewBox="0 0 20 20" className="heart-stroke">
                        <path fillRule="evenodd" fill="white" clipRule="evenodd" d="M9.99413 4.66852C8.328 2.72067 5.54963 2.19671 3.46208 3.98035C1.37454 5.76399 1.08064 8.74615 2.72 10.8557C4.08302 12.6096 8.20798 16.3088 9.55992 17.506C9.71117 17.64 9.7868 17.707 9.87502 17.7333C9.95201 17.7562 10.0363 17.7562 10.1132 17.7333C10.2015 17.707 10.2771 17.64 10.4283 17.506C11.7803 16.3088 15.9052 12.6096 17.2683 10.8557C18.9076 8.74615 18.6496 5.74523 16.5262 3.98035C14.4028 2.21547 11.6603 2.72067 9.99413 4.66852Z" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <button
                        onClick={async (e) => {
                            e.stopPropagation();
                            if (isFavorite(id)) {
                                remove(id);
                            } else {
                                add(id);
                            }
                        }}
                        className={clsx('heart-clip', isFavorite(id) && 'favorited')} />
                </div>
            </div>

            {(renderPrivateImage && !item.private_preview_image_url) ? <SmallLogo className="cursor-pointer lg-tablet:h-[177px] h-[120px] object-cover lg-tablet:w-[150px] lg-tablet:max-h-[177px] w-full lg-tablet:min-w-[150px] block p-12 [&>path]:fill-blue2" /> : <ResponsiveImage onClick={() => router.push(`/item/${item.slug}`)} alt={name} src={renderPrivateImage ? item.private_preview_image_url : firstImage?.url} imageWidth={150} width={150} height={177} className="cursor-pointer lg-tablet:h-[177px] h-[120px] object-cover lg-tablet:w-[150px] lg-tablet:max-h-[177px] w-full lg-tablet:min-w-[150px] block" />}

            <div className="lg-tablet:px-7 lg-tablet:py-5 w-full  pb-[20px] tablet:px-[15px] ">
                <ProductBreadCrumbs item={item} className="text-sm font-[500] !mb-[8px] mt-[20px] lg-tablet:mt-0 !justify-start px-[20px] lg-tablet:px-[0]" />
                <div className="flex lg-tablet:flex-row flex-col justify-between space-x-[24px] mt-[10px] ">

                    <div className="lg-tablet:w-[45%] w-full mb-[20px] lg-tablet:mb-0 px-[20px] lg-tablet:px-0 flex flex-col justify-center">
                        <Link
                            prefetch={false}
                            href={`/item/${item.slug}`}
                            onClick={() => {
                                onActionClicked?.();
                            }}><h3 className="text-[24px] lg-tablet:h-[44px] min-w-[177px]  font-[500] font-heading leading-[22px] mb-2 line-clamp-2">{name}</h3>
                        </Link>
                        <div className="flex lg-tablet:space-x-2">
                            <AuthCode type={item.authentication_type} id={`row-${item.id}`}>
                                {trId}
                            </AuthCode>
                            {trending && <ProductBadge type="trending" theme="black" />}
                        </div>
                    </div>

                    {hasAuction && auctionType === Auction.TypeEnum.Auction ?
                        <>
                            <div
                                className="flex lg-tablet:w-[32%] lg-tablet:min-w-[104px] w-[92%] lg-tablet:flex-col tablet:px-[10px] h-auto justify-between lg-tablet:justify-center items-start text-[16px] sm-tablet:text-[14px] lg-tablet:px-[17px] text-blue9 font-[500] lg-tablet:border-l-[1px] lg-tablet:border-r-[1px] lg-tablet:border-b-[0px] lg-tablet:border-t-[0px] border-l-[0px] border-r-[0px] border-b-[1px] border-t-[1px] py-2 lg-tablet:space-y-[8px] lg-tablet:w-unset">
                                <span className="flex items-center">
                                    <ShoppingBagIcon />
                                    <span className="">{auction?.amount_of_bids} {i18next.t('item-text.bids')}</span>
                                </span>
                                {endTime && (
                                    <span className="flex items-center">
                                        <SandWatchIcon />
                                        <span className=""><BidTimer dueDate={endTime} /></span>
                                    </span>
                                )}
                                {endTime && (
                                    <span className="flex items-center">
                                        <CalendarIcon />
                                        <span className=""><DateFormat date={endTime} customMask="mm/dd/yyyy h:MM TT" /></span>
                                    </span>
                                )}
                            </div>
                            <div className="mt-[10px] lg-tablet:w-[25%] w-[92%] mb-[20px] pb-[5px] border-b-[1px] lg-tablet:px-[20px] lg-tablet:px-[0] lg-tablet:border-none lg-tablet:pb-0 lg-tablet:my-0 justify-between lg-tablet:justify-center flex lg-tablet:flex-col h-auto">
                                <Price className="text-[24px] font-[700]" state={isWinning === true ? 'positive' : isWinning === false ? 'negative' : undefined} justification="L" number={auction?.current_amount ?? '0'} />
                                <div className="hidden lg-tablet:flex lg-tablet:flex-col lg-tablet:justify-start lg-tablet:items-start">
                                    {lastUserbid && <><div>{i18next.t('item-text.current-bid-text.last-bid')}: </div><Price className="text-[16px] font-[500]" justification="L" number={lastUserbid?.max_amount ?? '0'} /></>}
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-end lg-tablet:w-[29%] w-[92%]">
                                <Link className="w-full lg-tablet:w-[unset]  lg-tablet:px-[0] px-[15px]" prefetch={false} href={`/item/${item.slug}`}>
                                    <Button
                                        fullWidth={isMobile}
                                        size="md"
                                        onClick={() => {
                                            onActionClicked?.();
                                        }}>{i18next.t('button-text.view')}
                                    </Button>
                                </Link>
                            </div>
                        </> :
                        <>
                            <div />
                            <div className="h-full flex flex-col justify-center">
                                <Link className="w-full lg-tablet:w-[unset]  lg-tablet:px-[0] px-[15px]" prefetch={false} href={`/item/${item.slug}`}>
                                    <Button
                                        fullWidth={isMobile}
                                        size="md"
                                        onClick={() => {
                                            onActionClicked?.();
                                        }}>{i18next.t('button-text.view')}
                                    </Button>
                                </Link>
                            </div>
                        </>}

                </div>
            </div>
        </div>
    );
};

export default ProductRow;
/* eslint-enable react-hooks/rules-of-hooks */
